<div class="container">

  <div class="featurette-divider"></div>

  <div class="row">
    <div class="col-md-6 order-md-2" data-aos="fade-right">
      <h2 class="titulares"><b>Desarrollo de <B style="color: #764A98;">sistemas a medidas.</B></b><br></h2> <span
      class="text-muted subTitulo">Lo adaptamos a tu negocio</span>
      <p class="lead">Cuando hay <b style="font-weight: 900;">una necesidad</b> donde no hay herramienta estándar en el
        mercado, una buena solución es el desarrollo a medida<br></p>
      <div class="estiloSeparador"></div>
      <p class="textoObjetivos"><i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Nos adaptamos a tu
        necesidad<br>
        <i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Soluciona problemas específicos<br>
        <i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Mejora herramientas ya existentes<br>
        <i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Sé mas competente frente a tu competencia</p>

    </div>
    <div class="col-md-6 order-md-1" data-aos="fade-right">
      <object>
        <app-svg-monitor></app-svg-monitor>
      </object>
    </div>
  </div>

  <div class="row separacionSuperior">
    <div class="col-md-6" data-aos="fade-right">
      <h2 class="titulares"><b>Desarrollo <br><B style="color: #764A98;">móvil.</B></b><br></h2> <span
      class="text-muted subTitulo">Crea tu aplicación</span>
      <p class="lead">Para tener visibilidad y considerarse competitivo en el mercado actual se debe conocer las
        necesidades de los usuarios en torno a las nuevas tecnologías. La aparición de las aplicaciones en la vida
        cotidiana están modificando los hábitos de
        consumo
        <br></p>
      <div class="estiloSeparador"></div>

      <a class="btn colorBoton btn-lg" href="#contacto">Contactar</a>

    </div>
    <div class="col-md-6" data-aos="fade-right">

      <object>
        <app-svg-celular></app-svg-celular>
      </object>
    </div>
  </div>

  <div class="row separacionSuperior">
    <div class="col-md-6 order-md-2" data-aos="fade-right">
      <h2 class="titulares"><b>Diseño Gráfico para <B style="color: #764A98;">identidad empresarial.</B></b><br></h2>
      <span class="text-muted subTitulo">Planificación y desarrollo</span>
      <p class="lead">El prestigio de tu empresa se basará en lo bien que esté desarrollada <b
        style="font-weight: 900;">la identidad gráfica.</b> Una empresa que no sea atractiva a la vista del usuario, es
        una empresa que perderá clientes.<br></p>
      <div class="estiloSeparador"></div>
      <p class="textoObjetivos"><i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Diseño Web y móvil<br>
        <i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Branding<br>
        <i class="fa fa-circle tamañoBotonOp" aria-hidden="true"></i> Diseño publicitario y comunicación</p>

    </div>
    <div class="col-md-6 order-md-1" data-aos="fade-right">
      <div class="cuadradoMonitorDisenio">
        <app-svg-disenio></app-svg-disenio>
        <div class="cuadradoMonitorObjetos">
          <app-svg-disenio-objetos></app-svg-disenio-objetos>

        </div>
      </div>
    </div>

    <!-- /END THE FEATURETTES -->
  </div>

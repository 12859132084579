<div class="container marketing">
  <div class="row">
    <div class="col-lg-4 tipografiaBotones" data-aos="fade-up">
      <img src="../../assets/img/bidatos.svg" style="max-width: 180px; max-height: 200px; margin-bottom: 20px;">
      <h1>Captura<b style="color: #8b44ba;"> de datos </b></h1>
      <p class="h3">No hay una sola forma de resolver el problema.
        <B> Nos enfocamos </B> en ver cuál es el mejor camino para llegar al resultado esperado.</p>
    </div>
    <div class="col-lg-4 " data-aos="fade-up">
      <img src="../../assets/img/bipanel.svg" style="max-width: 180px; max-height: 200px; margin-bottom: 20px;">

      <h1 class="titularesTipografia">Procesar la <b style="color: #8b44ba;">información </b></h1>
      <p class="h3">Todo entra por la vista y un
        <B>buen desarrollo </B>no puede estar acompañado de un mal diseño. La unión hace la diferencia.</p>
    </div>
    <div class="col-lg-4" data-aos="fade-up">
      <img src="../../assets/img/bipanel2.svg" style="max-width: 180px; max-height: 200px;margin-bottom: 20px;">
      <h1 class="titularesTipografia">Panel de <b style="color: #8b44ba;"> soluciones </b></h1>
      <p class="h3"> Nos enfocamos en
        <B>"Qué"</B> estamos haciendo y no en
        <B>"Cómo"</B> se está haciendo. Daremos el mejor resultado posible </p>
    </div>
  </div>
</div>

<div class="conteiner">
  <div class="text-center">

    <div class="row">
      <div class="col-lg-12 col-md-12 tecnologiaCaja" data-aos="fade-up" data-aos-duration="1000">

        <h1 class="tituloTecnologias">¿Con qué tecnologías trabajamos?<br></h1>
        <h3 class="subtituloTecnologias"><b style="color: #8b44ba;">Front End</b></h3>
      </div>
    </div>

    <div class="row justify-content-center align-items-center imagenesTecnologias">
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/angular-logo-png.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/ionic.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/flutter.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/bootstrap.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/html.png">
      </div>

    </div>

  </div>
</div>

<div class="conteiner">
  <div class="text-center paddingInferior">

    <div class="row paddingSuperior">
      <div class="col-lg-12 col-md-12 tecnologiaCaja" data-aos="fade-up" data-aos-duration="1000">

        <h3 class="subtituloTecnologias"><b style="color: #8b44ba;">Back End</b></h3>
      </div>
    </div>

    <div class="row justify-content-center align-items-center imagenesTecnologias">
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/java.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/go.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/phyton.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/node.png">
      </div>
      <div class="col-lg-2 col-md-2" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/php.png">
      </div>

    </div>

  </div>
</div>

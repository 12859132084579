<div class="content">
    <div class="imagenSocio">
        <div class="textoSocio">
            <p>LA CONFIANZA QUE NOS TIENEN</p>
            <h1>Trabajamos actualmente con <br><b>BNP Paribas Cardif</b></h1>
            <div class="logoSocio">
                <img src="../../../assets/img/bnpLogo.png" alt="">
            </div>
        </div>
    </div>
</div>

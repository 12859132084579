import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
})
export class QuienesSomosComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

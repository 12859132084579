<div class="fondoBannerCentral bg-gradient">

  <div class="row">

    <div class="col-md-12 order-md-12 estiloTextoBannerCentral " data-aos="fade-up" data-aos-duration="1000">
      <h2 class="titulares"><b>Reciba el asesoramiento que su empresa necesita</b></h2>

      <a class="btn btn-light estiloBotonInferior" href="#contacto">Contactar</a>
    </div>

  </div>

</div>

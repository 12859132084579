<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
	<path class="st1" d="M341.7,341h-77.1c-6.8,0-12.3-5.5-12.3-12.3l0,0c0-6.8,5.5-12.3,12.3-12.3h77.1c6.8,0,12.3,5.5,12.3,12.3l0,0
	C354,335.5,348.5,341,341.7,341z"/>
	
	<path class="st0" d="M359.4,68.6c0.7,1.2,2.1,0.9,3.1,1.3c10.3,3.8,16.9,12.9,16.9,23.8c0,54.2,0,108.5,0,162.7
	c0,51.9,0,103.8,0,155.7c0,16.7-10.4,27.2-27.1,27.3c-43.7,0.2-87.4,1.7-131.1,0.4c-10.8-0.3-21.6-0.5-32.3-1.9
	c-3.5-0.4-6,1.9-6.1,5.5c-0.1,4.4,0,8.7-0.1,13.1c-0.1,4.7-2.2,6.9-6.8,6.9c-30.2,0-60.4,0-90.6,0c-6.3,0-10.3-3.6-12.8-8.9
	c-2.4-5.1-3-10.6-3.2-16.1c-0.5-13.9,1.5-27.7,4.1-41.3c4.7-25.4,9.7-50.8,14.9-76.2c3.9-19.1,14.1-35.1,26.1-50
	c15.2-19,33-35.2,52-50.3c2.2-1.8,3.1-3.5,3.1-6.4c-0.1-38.2,0.2-76.3-0.2-114.5c-0.2-15.5,4.5-25.9,20.4-31.1
	C246.3,68.6,302.8,68.6,359.4,68.6z M182,333.7c2.7-3.1,4.1-6.6,5.5-10c4.8-11.1,7.9-22.7,12.1-34c2.5-6.6,5.7-12.6,11.1-17.3
	c3.8-3.4,8.2-5.1,13.4-4.9c9.6,0.4,19.9,8.8,22.6,19.7c4.6,18.4,0.9,35.4-10.9,50.3c-3,3.8-5.3,7.8-6.7,12.4c-1,3.4-0.3,4.2,3.3,4.2
	c43.3-0.1,86.6-0.1,129.9,0c3.8,0,4.9-0.8,4.9-4.8C367,274,367,198.5,367,123.1c0-5,0-5-5-5c-58.5,0-116.9,0-175.4-0.1
	c-3.6,0-4.7,0.7-4.7,4.5c0.1,58.6,0.1,117.2,0.1,175.8C182,310.2,182,321.9,182,333.7z M169.7,234.2c-12.1,9.8-22.8,19.6-32.9,30.3
	c-12.3,13-23.3,27-30.8,43.5c-4.8,10.4-6.2,21.6-8.4,32.6c-5.3,27-11.8,53.8-14.7,81.3c-0.9,8.2-1.4,16.4,0.2,24.5
	c0.7,3.4,2.2,4.8,6,4.8c24-0.2,48.1-0.1,72.1-0.1c9.3,0,9.5,0,9.2-9.4c-0.1-4.2,1.1-7.3,4.3-10.1c6.1-5.3,11.5-11.4,16.8-17.5
	c14.1-15.9,24.2-33.2,23.8-55.5c-0.2-11.2,3.9-21.2,11.3-29.9c8-9.4,10.2-20.9,9.3-32.9c-0.4-5.9-2.8-11.2-8.3-14.5
	c-3.8-2.2-6.2-2-9.3,1.1c-3.4,3.4-5.5,7.7-7.1,12.2c-2.9,8.1-5.5,16.3-8.5,24.3c-3.4,9.4-6.9,18.8-13,26.9c-2.3,3-5,5.5-8.8,6.6
	c-6.4,1.8-11.3-1.8-11.3-8.4c0-26.9,0-53.7,0-80.6C169.7,254.1,169.7,244.6,169.7,234.2z M197.9,426.3c4.7,0.3,8.9,0.8,13.2,0.9
	c47.3,1.6,94.5,0.1,141.8-0.3c9.3-0.1,14.1-5.2,14.1-14.6c0-13.7-0.1-27.5,0.1-41.2c0.1-3.4-0.8-4.3-4.2-4.3
	c-29.9,0.1-59.9,0.1-89.8,0.1c-14.1,0-28.3,0.1-42.4-0.1c-2.9,0-4.2,0.6-4,3.8c0.2,3.5-0.4,6.9-1.2,10.3c-2.6,11.1-8.4,20.6-15,29.6
	C206.5,415.8,202.3,420.8,197.9,426.3z M274.1,105.7c28,0,56,0,84,0c9,0,9,0,9-8.9c0-11.1-4.8-15.8-16.1-15.8c-29.8,0-59.6,0-89.4,0
	c-22,0-43.9,0-65.9,0c-8.4,0-13.7,5.3-13.7,13.7c0,1.9,0,3.9,0,5.8c0.1,5.8-1.2,5.2,5.4,5.2C216.3,105.7,245.2,105.7,274.1,105.7z"
	/>
<path class="st5" d="M296.9,397.1c0,12.2-10.1,22.3-22.2,22.4c-12.5,0.1-22.7-10.3-22.7-22.8c0.1-12.3,10-22.3,22.3-22.4
	C286.7,374.1,296.9,384.4,296.9,397.1z M274.6,387c-5.6-0.1-10.1,4.2-10.1,9.8c0,5.5,4.4,10,10,10c5.4,0,9.8-4.3,10-9.6
	C284.7,391.7,280.2,387,274.6,387z"/>
<path class="st5" d="M288.7,87.1c6.2,0,12.3,0,18.5,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-24.9,0.1-37.3,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C276.4,87.1,282.6,87.1,288.7,87.1L288.7,87.1z"/>
<path class="st1" d="M224.4,137.8c6.2,0,54.8,0,61,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-67.4,0.1-79.8,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C212.1,137.8,218.3,137.8,224.4,137.8L224.4,137.8z"/>
<path class="st1" d="M224.4,183.5c6.2,0,54.8,0,61,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-67.4,0.1-79.8,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C212.1,183.5,218.3,183.5,224.4,183.5L224.4,183.5z"/>
<path class="st1" d="M318.2,137.8c6.2,0,18,0,24.2,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-19.2,0.1-31.6,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C317.2,137.8,312.1,137.8,318.2,137.8L318.2,137.8z"/>
<path class="st1" d="M213.3,160.8c6.2,0,18,0,24.2,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-19.2,0.1-31.6,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C212.3,160.8,207.2,160.8,213.3,160.8L213.3,160.8z"/>
<path class="st1" d="M266,160.8c6.2,0,18,0,24.2,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-19.2,0.1-31.6,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C265.1,160.8,259.9,160.8,266,160.8L266,160.8z"/>
<path class="st1" d="M318.7,160.8c6.2,0,18,0,24.2,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-19.2,0.1-31.6,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C317.8,160.8,312.6,160.8,318.7,160.8L318.7,160.8z"/>
<path class="st1" d="M213.9,206.4c6.2,0,18,0,24.2,0c4.2,0,6.8,2.4,6.9,6.1s-2.5,6.2-6.7,6.3c-12.4,0.1-19.2,0.1-31.6,0
	c-4.1,0-6.8-2.7-6.8-6.3c0.1-3.5,2.9-6.1,7-6.1C212.9,206.4,207.8,206.4,213.9,206.4L213.9,206.4z"/>
<path class="st5" d="M246.8,99.5c-2.2,0-4.4,0.2-6.5,0c-3.5-0.3-5.2-2.7-5.5-5.9c-0.3-3,2.3-6.1,5.5-6.3c4.3-0.2,8.7-0.2,13.1,0
	c3.2,0.1,5.7,3.1,5.7,6.1c0,3.2-2.4,5.7-5.7,6.1c-0.3,0-0.5,0.1-0.8,0.1C250.7,99.6,248.8,99.6,246.8,99.5
	C246.8,99.5,246.8,99.5,246.8,99.5z"/>
<path class="st1" d="M264.1,218.8c-2.2,0-4.4,0.2-6.5,0c-3.5-0.3-5.2-2.7-5.5-5.9c-0.3-3,2.3-6.1,5.5-6.3c4.3-0.2,8.7-0.2,13.1,0
	c3.2,0.1,5.7,3.1,5.7,6.1c0,3.2-2.4,5.7-5.7,6.1c-0.3,0-0.5,0.1-0.8,0.1C268,218.9,266.1,218.9,264.1,218.8
	C264.1,218.8,264.1,218.8,264.1,218.8z"/>
<g>
	<path class="st4" d="M282.4,328.6c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.4,0.3-0.7,0.3h-2.9v1.8h3.3c0.4,0,0.7,0.1,0.8,0.4
		c0.2,0.3,0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.4-0.8,0.4H277c-0.4,0-0.8-0.1-1.1-0.3s-0.4-0.5-0.4-0.9v-9.3
		c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.7-0.3,1.1-0.3h4.6c0.4,0,0.7,0.1,0.8,0.4s0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1s-0.4,0.4-0.8,0.4h-3
		v1.9h2.9c0.4,0,0.6,0.1,0.7,0.4C282.3,327.9,282.4,328.2,282.4,328.6z"/>
	<path class="st4" d="M287.5,333.4c0,0.4-0.1,0.7-0.4,0.9c-0.3,0.2-0.6,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3
		c-0.3-0.2-0.4-0.5-0.4-0.9v-9.9c0-0.2,0-0.4,0.1-0.5c0.1-0.1,0.2-0.3,0.4-0.3s0.3-0.2,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1
		c0.5,0,1,0.1,1.2,0.3s0.5,0.5,0.7,0.9l2.9,5.8v-5.8c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.6-0.3,1.1-0.3c0.4,0,0.8,0.1,1.1,0.3
		c0.3,0.2,0.4,0.5,0.4,0.9v9.9c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4,0.1-0.6,0.1
		c-0.6,0-1-0.1-1.3-0.4c-0.3-0.3-0.6-0.6-0.8-1.1l-2.7-5.1V333.4z"/>
	<path class="st4" d="M302.5,323.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.7-0.1
		c0.4,0,0.7,0.1,0.9,0.2c0.2,0.2,0.3,0.4,0.3,0.8c0,0.1,0,0.3-0.1,0.4l-2.5,9.5c-0.1,0.4-0.3,0.6-0.5,0.8c-0.3,0.1-0.6,0.2-0.9,0.2
		h-1.2c-0.4,0-0.7-0.1-1-0.2c-0.3-0.2-0.5-0.4-0.6-0.7l-2.8-9.5c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.2,0.4-0.3
		c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.6,0,1,0.1,1.3,0.3c0.3,0.2,0.4,0.5,0.5,0.7l1.7,7.1L302.5,323.5z"/>
	<path class="st4" d="M308.4,334.6c-0.4,0-0.8-0.1-1.1-0.3s-0.4-0.5-0.4-0.9v-9.8c0-0.4,0.1-0.7,0.4-0.9s0.7-0.3,1.1-0.3
		s0.8,0.1,1.1,0.3s0.4,0.5,0.4,0.9v9.8c0,0.4-0.1,0.7-0.4,0.9S308.8,334.6,308.4,334.6z"/>
	<path class="st4" d="M314.2,333.6c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.6,0.3-1.1,0.3c-0.4,0-0.8-0.1-1-0.2
		c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.1,0-0.3,0.1-0.4l2.7-9.6c0.1-0.3,0.2-0.6,0.5-0.7c0.2-0.1,0.5-0.2,0.9-0.2h1.8
		c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.4,0.5,0.7l2.7,9.5c0,0.1,0.1,0.3,0.1,0.4c0,0.4-0.1,0.6-0.4,0.8c-0.3,0.2-0.6,0.2-1,0.2
		c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.2-0.4l-0.3-1h-3.4L314.2,333.6z
		 M315.1,329.9h2.1l-1.1-4.1L315.1,329.9z"/>
	<path class="st4" d="M331,333.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.6,0.3-1,0.3
		c-0.6,0-1-0.1-1.3-0.3c-0.3-0.2-0.5-0.5-0.7-0.9l-1.4-3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0v3.1c0,0.4-0.1,0.7-0.4,0.9
		s-0.7,0.3-1.1,0.3s-0.8-0.1-1.1-0.3s-0.4-0.5-0.4-0.9v-9.6c0-0.4,0.1-0.7,0.4-0.9c0.3-0.2,0.7-0.3,1.1-0.3h2.3
		c0.6,0,1.2,0.1,1.8,0.2c0.6,0.1,1,0.3,1.4,0.6s0.7,0.6,1,1.1c0.2,0.5,0.4,1.1,0.4,1.8c0,0.5,0,0.9-0.1,1.3
		c-0.1,0.4-0.2,0.7-0.3,0.9s-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.7,0.4L331,333.1z M325.7,327.8c0.1,0,0.2,0,0.3,0
		c0.1,0,0.2,0,0.4,0c0.5,0,0.8-0.1,1-0.3s0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.3-1-0.3h-0.7V327.8z"/>
</g>

</svg>
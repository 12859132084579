import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-monitor',
  templateUrl: './svg-monitor.component.html',
  styleUrls: ['./svg-monitor.component.css']
})
export class SvgMonitorComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

<div class="container marketing">
  <div class="row">
    <div class="col-lg-4 tipografiaBotones" data-aos="fade-up">
      <img src="../../assets/img/btn2.png" style="max-width: 180px; max-height: 180px;">
      <h1>Análisis<b style="color: #8b44ba;"> del problema </b></h1>
      <p class="h3">No hay una sola forma de resolver el problema.
        <B> Nos enfocamos </B> en ver cuál es el mejor camino para llegar al resultado esperado.</p>
    </div>
    <div class="col-lg-4 " data-aos="fade-up">
      <img src="../../assets/img/btn1.png" style="max-width: 180px; max-height: 180px;">

      <h1 class="titularesTipografia">Diseño <b style="color: #8b44ba;"> intuitivo </b></h1>
      <p class="h3">Todo entra por la vista y un
        <B>buen desarrollo </B>no puede estar acompañado de un mal diseño. La unión hace la diferencia.</p>
    </div>
    <div class="col-lg-4" data-aos="fade-up">
      <img src="../../assets/img/btn3.png" style="max-width: 180px; max-height: 180px;">
      <h1 class="titularesTipografia">Programación <b style="color: #8b44ba;"> funcional </b></h1>
      <p class="h3"> Nos enfocamos en
        <B>"Qué"</B> estamos haciendo y no en
        <B>"Cómo"</B> se está haciendo. Daremos el mejor resultado posible </p>
    </div>
  </div>
</div>

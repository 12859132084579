<body class="landing-page landing-page1">
    <div class="cuadradoVioletaLiso"></div>
    <nav class="navbar navbar-dark navbar-expand-lg navbar-top">
        <div class="container-fluid">
            <img routerLink="/home" class="p-5 img-fluid pr-3 aa-logo-img tamañoLogoIcono" src="../../../../assets/img/logoCompletoAngema.svg" alt="logo">
            <button class=" navbar-toggle" data-target="#menu" data-toggle="collapse" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>

            <div class="collapse navbar-collapse" id="menu">
                <ul class="navbar-nav ml-auto mr-auto">

                    <li class="nav-item">
                        <a class="nav-link h2" href="#servicio"> Servicios</a>
                    </li>
                    <li class="nav-item"><a class="nav-link h2" href="#tecnologia">Tecnologías</a></li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/appHotel">Hotel App</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/bi">Business Intelligence</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/quienesSomos">Nosotros</a>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-outline-light botonLogin" href="#contacto"><b>Contacto</b> </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
    <div class="cuadradoVioletaQuienes"></div>

    <div class="">
        <div class="parallax filter-gradient" data-color="blue">
            <div class="parallax-background">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 hidden-xs">
                            <div class="parallax-image quienes">
                                <img class="somos" src="../../../../assets/img/appHotel.svg" style="margin-top: 70px" />
                            </div>
                        </div>
                        <div class="col-md-5 col-md-offset-1">
                            <div class="description">
                                <h2><b class="tituloHeader">App Hotel
                  </b><br>La nueva solución</h2>
                                <br>
                                <h5><br> Es un nuevo servicio destinado a la industria hotelera para mejorar la administración, incrementar los ingresos y maximizar el confort de los huéspedes. <br><br>

                                    <b>¿Querés conocer de que se trata? </b>
                                    <h3> Mirá nuestro video tutorial </h3>



                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-end espaciadoVideo">
        <div class="col-md-3 hidden-xs">
            <img src="../../../assets/img/vectorLateral3.svg" class="float-right imagenIzquierdaApp">
        </div>
        <div class="col-md-6">
            <div id="videoHotel" class="videoHotel">
                <video src="../../../assets/video/appHotel.mp4" autoplay width=100% height=100% controls style="border: 10px solid #8b44ba;"></video>
            </div>
        </div>
        <div class="col-md-3 hidden-xs">
            <img src="../../../assets/img/vectorLateral4.svg" class="float-left imagenDerechaApp">
        </div>
    </div>

    <div class="container botonesAppHotel ">
        <div class="row">
            <div class="col-lg-4 tipografiaBotones" data-aos="fade-up">
                <img src="../../assets/img/ganancias.svg" style="max-width: 180px; max-height: 200px; margin-bottom: 20px;">
                <h1>Mayores<b style="color: #8b44ba;"> ingresos </b></h1>
                <p class="h3">El tener un facil acceso a los productos que ofrece su hotel el huésped
                    <B> consumirá más </B> maximizando las ganancias.</p>
            </div>
            <div class="col-lg-4 " data-aos="fade-up">
                <img src="../../assets/img/agilidad.svg" style="max-width: 180px; max-height: 200px; margin-bottom: 20px;">

                <h1 class="titularesTipografia">Agiliza <b style="color: #8b44ba;">Procesos </b></h1>
                <p class="h3">Gana tiempo para que menos personas
                    <B>hagan más tareas. </B>Ayudará a descomprimir las responsabilidades del personal.</p>
            </div>
            <div class="col-lg-4" data-aos="fade-up">
                <img src="../../assets/img/error.svg" style="max-width: 180px; max-height: 200px;margin-bottom: 20px;">
                <h1 class="titularesTipografia">Evita <b style="color: #8b44ba;"> errores </b></h1>
                <p class="h3"> Uno de las mayores incomodidades son
                    <B>los errores</B> cometidos por el personal. Esto se puede
                    <B>evitar</B> si todos los procesos pasan por un sistema </p>
            </div>
        </div>
    </div>

    <div class="bannerInferior">
        <img src="../../../assets/img/fondoAppHotel.jpg">
    </div>

</body>
<app-footer></app-footer>
<body class="landing-page landing-page1">
<div class="cuadradoVioletaLiso"></div>

<nav class="navbar navbar-dark navbar-expand-lg navbar-top">
  <div class="container-fluid">
    <img routerLink="/home" class="p-5 img-fluid pr-3 aa-logo-img tamañoLogoIcono"
         src="../../../../assets/img/logoCompletoAngema.svg" alt="logo">
    <button class=" navbar-toggle" data-target="#menu" data-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="menu">
      <ul class="navbar-nav ml-auto mr-auto">

        <li class="nav-item">
          <a class="nav-link h2" href="#servicio"> Servicios</a>
        </li>
        <li class="nav-item"><a class="nav-link h2" href="#tecnologia">Tecnologías</a></li>
        <li class="nav-item">
          <a class="nav-link h2" routerLink="/bi">Business Intelligence</a>
        </li>
        <li class="nav-item">
          <a class="nav-link h2" routerLink="/quienesSomos">Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="btn btn-outline-light botonLogin" href="#contacto"><b>Contacto</b> </a>
        </li>
      </ul>
    </div>
  </div>


</nav>
<div class="cuadradoVioletaQuienes"></div>

<div class="">
  <div class="parallax filter-gradient" data-color="blue">
    <div class="parallax-background ">
      <div class="container">
        <div class="row">
          <div class="col-md-5 hidden-xs">
            <div class="parallax-image quienes">
              <img class="somos" src="../../../../assets/img/preguntas.svg" style="margin-top: 20px"/>
            </div>
          </div>
          <div class="col-md-6 col-md-offset-1">
            <div class="description">
              <h3> ¿Qué es Angema? </h3>
              <br>
              <h5><b>Es una empresa de desarrollo tecnológico</b> constituido principalmente por diseñadores gráficos y
                programadores. Una de las principales problemáticas en el mundo del desarrollo es que no
                <B>piensan en el usuario</B>, como interactúa con el programa/servicio con el cliente, si la interfaz es
                amigable o no, si es visualmente atractiva a la vista. <br><br> Por eso creamos Angema. Una empresa
                dedicada no solo al desarrollo de tecnología
                sino también a la experiencia del usuario.<br><br>

              </h5>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</body>

<div class="row bloqueInferior ">
  <div class="col-md-6">
    <div class="descripcionNosotros paddingInferior">

    </div>

  </div>
  <div class="col-md-4 ">
    <div class="descripcionNosotros paddingInferior ">
      <p><b class="colorVioleta">¿Dónde puedo contactarlos?</b><br> Puede llamarnos a cualquiera de nuestros números o
        mandar directamente un email a<b> info@angema.com.ar. </b><br> Nos envía su consulta y en un corto periodo de
        tiempo se lo resolveremos.
      </p>
      <br>

      <p><b class="colorVioleta">¿Me pueden hacer solo un diseño?</b><br> Efectivamente, nos encargamos de cualquier
        aspecto que su marca o empresa necesite. Diseño de identidad, empresarial, logos, etc.
      </p>
      <br>

      <p><b class="colorVioleta">¿Realizan visitas para consultas?</b><br> Por temas de la actual situación sanitaria
        que estamos afrontando, preferentemente evitamos las visitas por consultas. Normalmente realizamos conferencias
        virtuales. Pero llegado
        al caso que se requiera una visita personal, lo estaremos realizando con gusto.
      </p>
    </div>
  </div>
</div>

<app-footer></app-footer>

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-disenio',
  templateUrl: './svg-disenio.component.html',
  styleUrls: ['./svg-disenio.component.css']
})
export class SvgDisenioComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

<div class="content">
  <div class="row signup justify-content-center">
    <div class="col-md-4 order-md-2 bloqueTipografiaContacto paddingIzquierdo">
      <div class="form-group">
        <h2><b>Contanos<br> que tenés en<br></b> <b style="color: #8b44ba">mente</b></h2>
        <p>¡y comencemos a crear el<br> proyecto juntos!</p>
      </div>
    </div>

    <div class="col-md-4 order-md-2 marginDerecho">
      <form method="POST" class="register-form" id="register-form" #f="ngForm" (ngSubmit)="guardar(f)">
        <h1 class="form-title">Envía tu mensaje</h1>

        <div class="form-group">
          <label for="nombre"><i class="zmdi zmdi-account material-icons-name"></i></label>
          <input type="text" name="nombre" id="nombre" class="form-control" placeholder="Nombre"
                 [(ngModel)]="contacto.name" name="nombre" minlength="5" maxlength="30"
                 [class.is-invalid]="nombre.invalid && nombre.touched" #nombre="ngModel">
          <h3 *ngIf="nombre.invalid && nombre.touched"
              class="validacionTextoError text-danger coltext-danger animated fadeIn">El nombre debe contener entre 5 y 30 caracteres</h3>
        </div>

        <div class="form-group">
          <label for="empresa"><i class="zmdi zmdi-account material-icons-name"></i></label>
          <input type="text" name="empresa" id="empresa" class="form-control" placeholder="Empresa"
                 [(ngModel)]="contacto.company" name="empresa" minlength="2" maxlength="30"
                 [class.is-invalid]="empresa.invalid && empresa.touched" #empresa="ngModel">
          <h3 *ngIf="empresa.invalid && empresa.touched"
              class="validacionTextoError text-danger coltext-danger animated fadeIn">La empresa debe contener entre 2 y 30 caracteres</h3>
        </div>

        <div class="form-group">
          <label for="email"><i class="zmdi zmdi-email"></i></label>
          <input type="email" name="email" id="email" class="form-control" placeholder="Email"
                 [(ngModel)]="contacto.mail" name="email" [class.is-invalid]="email.invalid && email.touched"
                 #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
          <h3 *ngIf="email.invalid && email.touched"
              class="validacionTextoError text-danger coltext-danger animated fadeIn">Ingrese un email válido</h3>
        </div>

        <div class="form-group">
          <label for="tel"><i class="zmdi zmdi-account material-icons-name"></i></label>
          <input type="number" name="tel" id="tel" class="form-control" placeholder="Número de teléfono"
                 [(ngModel)]="contacto.phone" name="tel" minlength="8" maxlength="15"
                 [class.is-invalid]="tel.invalid && tel.touched" #tel="ngModel" pattern="^[0-9]{8,15}">
          <h3 *ngIf="tel.invalid && tel.touched"
              class="validacionTextoError text-danger coltext-danger animated fadeIn">Ingrese un Teléfono entre 8 a 15 caracter "-"</h3>
        </div>

        <div class="form-group">
          <label for="reservationMessage"><i class="zmdi zmdi-account material-icons-name"></i></label>
          <textarea style="resize: none" name="reservationMessage" class="form-control" id="reservationMessage" minlength="1" maxlength="250" cols="30"
                    rows="5" placeholder="Tu Mensaje" [(ngModel)]="contacto.description" name="reservationMessage"
                    [class.is-invalid]="mensaje.invalid && mensaje.touched" #mensaje="ngModel"></textarea>
          <h3 *ngIf="mensaje.invalid && mensaje.touched"
              class="validacionTextoError text-danger coltext-danger animated fadeIn">Ingrese un Mensaje</h3>
        </div>

        <div class="form-group form-button">
          <button type="submit" class="btn colorBoton btn-lg" name="signup" id="signup" value="Register"> Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

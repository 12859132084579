import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-celular',
  templateUrl: './svg-celular.component.html',
  styleUrls: ['./svg-celular.component.css']
})
export class SvgCelularComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-socio',
  templateUrl: './socio.component.html',
})
export class SocioComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

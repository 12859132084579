<footer class="footer-black aa-footer">
  <div class="container py-5">
    <div class="row text-center">
      <div class="col-md-3">
        <a class="btn btn-link btn-neutral" href="#">
          <img src="../../../assets/img/logoCompleto.svg"></a>
        <a class="btn btn-link btn-neutral" href="#">

        </a>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <ul class="footerEstilo">
              <span class="footerTitulo">Angema SAS</span>
              <hr class="footerHr">

              <li>
                <a href="" href="#servicio">Servicios</a>
              </li>
              <li>
                <a href="" href="#tecnologia">Tecnologías</a>
              </li>
              <li>
                <a href="" routerLink="/quienesSomos">Nosotros</a>
              </li>
              <li>
                <a href="#contacto">Contacto</a>
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            <ul class="footerEstilo">
              <span class="footerTitulo">Ayuda</span>
              <hr class="footerHr">
              <li>
                <a href="/preguntasFrecuentes">Preguntas Frecuentes</a>
              </li>

            </ul>
          </div>

          <div class="col-md-4">
            <ul class="footerEstilo">
              <span class="footerTitulo">Contacto</span>
              <hr class="footerHr">
              <li class="footerLista">
                <a href=""><b>info@angema.com.ar</b></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <p class="mt-3">&copy; 2021. Angema SAS. Todos los derechos reservados.</p>

      </div>
    </div>
  </div>
</footer>

<div class="container">
  <div class="col-md-12 order-md-12 col-sm-12 secuencia1">
    <div class="textoSecuencia1 col-md-8 order-md-8" data-aos="fade-right">
      Personaliza el panel de control con<br><b style="color: white;background-color: #8b44ba; padding: 2%"> gráficos a
      tu medida</b>
    </div>

    <div class=" col-md-12 order-md-12 col-sm-12 imagenSecuencia1" data-aos="fade-left">
      <img src="assets/img/secuencia1.gif">
    </div>
  </div>
</div>

<div class="container">
  <div class="col-md-12 order-md-12 secuencia2">

    <div class=" col-md-12 order-md-6 imagenSecuencia2" data-aos="fade-right">
      <img src="assets/img/secuencia2.gif">
    </div>

    <div class="textoSecuencia2 col-md-8 order-md-8" data-aos="fade-left">
      Obserba los datos<br><b style="color: white;background-color: #8b44ba; padding: 2%"> en tiempo real</b>
    </div>

  </div>
</div>


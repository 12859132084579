<div class="row">
  <div class="home fadeIn animated">
  </div>
</div>

<div class="fondoBotones">
  <app-navbar></app-navbar>
  <app-botones></app-botones>
</div>

<app-banner-central id="superior"></app-banner-central>
<app-tarjeta-desplazadas id="servicio"></app-tarjeta-desplazadas>
<app-socio></app-socio>
<app-tecnologias id="tecnologia"></app-tecnologias>
<app-contacto id="contacto"></app-contacto>
<app-footer></app-footer>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
  
    <g id="">
        <g>
            <path class="st0" d="M340.8,352.4c2.5-1.6,5.3-1.5,8.1-1.5c12.1,0,24.3,0,37.4,0c-3.3-3.4-5.7-6-8.2-8.7
                c-12.9-14.7-17.5-32.3-16.2-51.5c0.4-6.6,4.8-10.1,12.4-10.2c13.8-0.1,27.7,0,41.6,0c17.1,0,34.1,0,51.2,0
                c10.2,0,13.4,2.8,14.3,12.8c0.2,2.7,1.1,3,3.5,2.5c2.1-0.6,4.4-0.7,6.6-0.9c6.5-0.4,12.5,1,15.7,7.2c3.5,6.5,3.4,13.4-0.6,19.8
                c-3.8,6.3-9.9,10.1-16.2,13.4c-6.9,3.5-14.1,6.1-21.6,7.6c-4.6,1-8.2,3-11.1,6.7c1.1,1.9,2.7,1,4,1c11.6,0.1,40.6-2.5,40.5,2.6
                c0,2.6-0.9,3.7-2.2,5.3c-8,9.7-17.8,14.1-30.8,13.8c-32-0.7-64.1-0.6-96-0.1c-11.4,0.1-20.5-2.9-27.8-11.6c-1.2-1.6-3-2.7-4.5-4
                C340.8,355.3,340.8,353.9,340.8,352.4z M422,288c-15.5,0-31.1,0-46.7,0c-3.5,0-6.4,0.2-6.5,4.8c-0.6,23.2,7.5,41.9,27,55.3
                c2.7,1.9,5.6,2.8,8.9,2.7c10.7-0.1,21.4-0.2,32,0c4.8,0.1,8.8-1.3,12.5-4.2c17.2-12.8,25.1-30.1,25-51.4c0-7-0.4-7.4-7.2-7.4
                C452,288,437,288,422,288z M489.4,358.5c-45.5,0-90.3,0-135.7,0c3.7,4.3,7.8,6.4,12.7,6.4c36.9,0,73.8,0,110.6,0
                C481.8,364.8,485.9,362.9,489.4,358.5z M471.8,334.3c10.2-2.9,20.2-5.8,27.3-14.2c3.6-4.3,4.2-9.3,1.6-14.1
                c-2.5-4.6-7.1-4-11.5-3.5c-1.5,0.2-2.9,0.4-4.4,0.8c-2.4,0.4-3.7,1.6-4,4.4C479.6,317,476.3,325.7,471.8,334.3z"/>
            <path id="humo3" class="st1" d="M447.6,224.2c7.2,8.9,7.8,17.3,1.5,27.2c-3.3,5.3-3.7,10.5-0.6,15.9c1.3,2.4,2.1,4.7-0.8,6.2
                c-2.7,1.3-4.5-0.7-5.7-2.9c-4-7.5-4-15.1,0.7-22.3c3.7-5.7,4.6-11.2,1.1-17.3c-1.6-2.8-0.9-4.9,1.7-6.7
                C446.1,224.2,446.9,224.2,447.6,224.2z"/>
            <path id="humo2" class="st1" d="M426.3,224.2c7,7.6,8.1,17.2,2.5,26.1c-3.6,5.7-4.7,11.2-1.1,17.2c1.2,2.1,1.7,4.5-0.8,5.7
                c-2.4,1.2-4.3-0.1-5.5-2.4c-4.3-7.4-4.5-15.1,0-22.3c3.8-6.1,5.3-11.8,1.1-18.2c-1.6-2.6-0.3-4.6,1.6-6.3
                C424.7,224.2,425.5,224.2,426.3,224.2z"/>
            <path id="humo1" class="st1" d="M404.9,224.2c7.2,6.7,8.7,16.9,3.1,25.4c-3.9,6.1-5.1,11.8-1.3,18.2c1.1,1.9,1.3,4.2-0.9,5.4
                c-2.4,1.2-4.3,0-5.5-2.1c-4.3-7.2-4.7-14.7-0.3-21.7c4-6.4,5.6-12.5,1.2-19.2c-1.7-2.6,0.6-4,1.5-5.8
                C403.3,224.2,404.1,224.2,404.9,224.2z"/>
        </g>
    </g>
    <g id="">
        <g>
            <path class="st0" d="M72.5,216.3c0.3,0.8,0.9,0.9,1.7,1c5.1,0.8,9.4,2.8,12.6,7c4.8,6.4,5.4,13.7,4.4,21.4c-0.6,4-1.8,8-3.4,12
                c4.2,0,8.2,0,12.1,0c0.9,0,1-0.7,1.1-1.2c0.2-1.6,0.3-3.3,0.6-4.8c1.2-12.3,0.9-24.4-2.2-36.3c-3-11.8-9.7-23.3-18.7-31.7l2.5-8.2
                c10.9,10,20.5,25.3,23.9,39.6c3.3,13.6,3.5,27.3,1.6,41c-0.1,1,0,1.5,1.1,1.5c3.7-0.1,7.3-0.1,11,0c1.2,0,1.5-0.4,1.5-1.5
                c0-12.9,0-25.9,0-38.8c0-0.9-0.2-1.5-1.1-1.8c-2.6-1-4.7-2.7-6.5-4.8c-6.7-8-10.7-24-0.3-36c2.7-3.1,5.6-5.8,8.7-8.5
                c2.2-2,4-2.2,6.5-0.4c2.4,1.7,4.5,3.7,6.5,5.7c8.3,8.5,11.5,18.4,7.5,30c-2.2,6.5-5.7,12-12.7,14.4c-0.9,0.3-0.9,0.9-0.9,1.6
                c0,12.8,0,25.8,0,38.6c0,1.2,0.4,1.5,1.6,1.5c3.9,0,7.8,0.1,11.7-0.1c2.5-0.1,4.3,0.6,5.4,2.8c0,39.1,0,78.2,0,117.3
                c-1.1,2.1-2.8,2.8-5.3,2.8c-26.4-0.1-53,0-79.4,0c-3.5,0-4.7-1.2-4.7-4.7c0-37.8,0-75.7,0-113.5c0-3.4,1.2-4.6,4.6-4.6
                c4.8,0,9.7,0,14.5,0c0.8,0,1.5,0.1,1.9-0.8c3.1-6.7,5.1-13.7,3.8-21.3c-0.9-5.1-3.3-9-8.5-10.7c-1.1-0.3-1.8-0.2-2.1,1.1
                 M140.6,319.3c0-17.3,0-34.5,0-51.8c0-1.7-0.3-2.1-2.1-2.1c-23.3,0.1-46.7,0-69.9,0c-1.7,0-2.1,0.4-2.1,2.1c0,34.5,0,68.9,0,103.5
                c0,1.7,0.4,2.1,2.1,2.1c23.3,0,46.7,0,69.9,0c1.7,0,2.1-0.6,2.1-2.1C140.6,353.7,140.6,336.5,140.6,319.3z M122.1,177.6
                c-8.5,7-8.7,22.9-0.4,29.8c2,1.7,4.3,2.5,6.7,1.6c2.4-0.8,4.2-2.5,5.4-4.5c5.1-8.5,5.1-17.3-1.2-25.3c-0.7-0.9-1.5-1.8-2.8-2.6
                c0,1.3,0,2.4,0,3.4c0,1.1,0,2.2,0,3.3c-0.2,2.2-1.9,3.9-3.9,3.9c-2.1,0-3.8-1.8-3.8-4.2C121.9,181.3,122.1,179.6,122.1,177.6z"/>
        </g>
    </g>
    <path class="st9" d="M135.9,171.4c-2-2-4.2-4-6.5-5.7c-2.5-1.8-4.2-1.6-6.5,0.4c-3,2.7-6.1,5.4-8.7,8.5c-10.3,12-6.3,28,0.3,36
	c1.8,2.1,3.9,3.8,6.5,4.8c4.3,1.6,9.6,0.4,9.6,0.4c7-2.4,10.6-7.9,12.7-14.4C147.3,189.9,144.2,180,135.9,171.4z M133.8,204.3
	c-1.2,2.1-3,3.7-5.4,4.5c-2.6,0.9-4.8,0-6.7-1.6c-8.2-6.9-8.1-22.8,0.4-29.8c0,2,0,3.7,0,5.5c0,2.4,1.7,4.2,3.8,4.2
	c2,0,3.7-1.7,3.9-3.9c0.1-1.1,0-2.2,0-3.3c0-1,0-2,0-3.4c1.2,0.8,2,1.7,2.8,2.6C138.8,186.8,138.8,195.7,133.8,204.3z"/>
<path class="st2" d="M394.9,365.8l-10.3-24.4l-3.6-90.3l-20.4,51.7l-1.7,17.9l-16.4,22l-172.2-1.1v-20.9l-29.9-0.1V187.8l11.8,0.7
	l5.8-20.1H132l-9.3,23.3v146.5l-20.5,45.2l2.6,17.9l15.3,10.1l50.3-4.7v-22.3l171.8,2.5l16.8,20.8c0,0,37-1.8,38.8-3.9
	c1.8-2.1,5.4-4.6,5.3-15.5C402.8,377.3,394.9,365.8,394.9,365.8z"/>
<path class="st2" d="M354.4,333.3H184.1c-9,0-16.3-7.3-16.3-16.3V214.8c0-9,7.3-16.3,16.3-16.3h170.3c9,0,16.3,7.3,16.3,16.3V317
	C370.7,326,363.4,333.3,354.4,333.3z"/>
        <path class="st0" d="M388.7,340.1V186.8c0-12.8-10.3-23.1-23.1-23.1l0,0H142.3c-12.8,0-23.3,10.5-23.3,23.3v153l-21.6,43.1
        c-0.3,0.7-0.4,1.3-0.4,2v3c0,12.8,10.3,23.1,23.1,23.1l0,0h267.5c12.8,0,23.1-10.3,23.1-23.1l0,0v-3c0-0.7-0.2-1.5-0.4-2
        L388.7,340.1z M126.9,186.8c0-8.4,6.9-15.3,15.3-15.3c0.1,0,0.1,0,0.2,0h223.1c8.4-0.1,15.4,6.6,15.5,15.1c0,0.1,0,0.1,0,0.2v151.2
        H126.9V186.8z M124.8,345.9h258.3l17.5,34.6H300.5l-3.9-11.9c-0.8-2.7-3.3-4.5-6.2-4.6h-73.1c-2.8,0-4.9,2-6.2,4.7l0,0l-3.7,11.5
        H107.3L124.8,345.9z M387.4,403.6H120.4c-8.4,0.1-15.4-6.5-15.5-15c0-0.2,0-0.3,0-0.6h105.4c1.7,0,3.1-1.1,3.7-2.7l4.5-13.5h71.2
        l4.5,13.7c0.6,1.6,2,2.7,3.7,2.7l105.4-0.1C403,396.7,396,403.6,387.4,403.6L387.4,403.6z"/>
    <path class="st9" d="M362.8,183.9H144.3c-2.1,0-3.9,1.8-3.9,3.9v132.9c0,2.1,1.8,3.9,3.9,3.9h218.5c2.1,0,3.9-1.8,3.9-3.9V187.8
        C366.6,185.7,364.9,183.9,362.8,183.9z M358.9,316.7H148.3v-125h210.6V316.7z"/>
    <path class="st9" d="M133.4,353.7h-2.8c-2.1,0-3.9,1.8-3.9,3.9c0,2.1,1.8,3.9,3.9,3.9h2.8c2.1,0,3.9-1.8,3.9-3.9
        C137.4,355.5,135.6,353.7,133.4,353.7z"/>
    <path class="st9" d="M193.7,353.7H147c-2.1,0-3.9,1.8-3.9,3.9c0,2.1,1.8,3.9,3.9,3.9h46.7c2.1,0,3.9-1.8,3.9-3.9
        C197.6,355.5,195.8,353.7,193.7,353.7z"/>
        <path id="tulipan2" class="st10" d="M72.5,216.3c-3.1-6.9-9-10-15.9-11.9c-10.5-2.9-19.5,0-27.2,7.2c-2.2,2.1-4,4.7-6.6,6.5
        c-0.6,0.3-0.8,1.2-1.2,1.9c0,0.8,0,1.6,0,2.5c5.3,8.3,11.9,15.4,21.8,17.4c10.1,2,19.5-0.6,26.8-8.5c1.3-1.5,2.4-3.3,2.8-5.2
        C74.4,221,72.5,216.3,72.5,216.3z M62.1,228.5c-6.1,4.4-16.6,6.7-24.5,0.3c-1.6-1.3-3.3-2.6-4.9-4.6c2.7,0,4.9,0,7.2,0
        c2.7,0,4.3-1.5,4.3-3.7c0-2.4-1.6-3.8-4.3-3.8c-1.5,0-2.9,0-4.7,0c1.8-2.1,3.8-3.4,6-4.3c7.4-3.1,14.5-1.9,20.9,2.6
        C67.6,218.9,67.6,224.6,62.1,228.5z"/>
    <path id="tulipan1" class="st11" d="M83.7,162.5c-3-6.6-7.2-12.4-14.2-15.4c-2.6-1.1-5.3-1.8-8.1-2.1c-0.6-0.1-13.2,0-14.5,0.3
        c-1.8,0.4-3,1.5-3.3,3.1c-1.2,7.6-1.3,15.3,1.8,22.5c3.7,8.8,11,13.5,19.9,16c5.2,1.3,10.1,0.8,14.6-2.4c0,0,4.4-2.1,5.3-8.1
        C86.4,171.5,85.7,166.9,83.7,162.5z M68.3,179.7c-10.2-1.9-17.8-9.6-17.8-20.9c0-0.2,0.1-0.4,0.1-0.7c0.2-0.1,0.3-0.2,0.6-0.4
        c1.2,1.5,2.5,2.9,3.8,4.3c1.7,1.7,3.8,1.8,5.4,0.2c1.7-1.6,1.8-3.7,0-5.5c-1.3-1.5-2.9-2.7-4.3-4.3c6-0.3,11.1,1.1,15.4,5.3
        c3.3,3.1,5.3,7.1,6.3,11.5C79.7,176.1,75.4,181,68.3,179.7z"/>
        <g id="codigo" class="st3">
            <path class="st4" d="M230.2,263.6c1.4,0.5,2.4,1.1,3.1,1.7c0.7,0.7,1,1.8,1,3.3c0,1.3-0.3,2.3-0.9,2.9c-0.6,0.6-1.4,0.9-2.3,0.9
                c-0.5,0-1-0.1-1.5-0.3l-28.8-10c-1.2-0.4-2.1-1-2.8-1.8c-0.6-0.8-1-1.8-1-3.1V254c0-2.5,1.2-4.2,3.7-5.1l28.8-10
                c0.5-0.2,1.1-0.3,1.5-0.3c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.5,0.9,2.9c0,1.6-0.3,2.7-1,3.3c-0.7,0.7-1.7,1.2-3.1,1.7l-24.5,8.1
                L230.2,263.6z"/>
            <path class="st4" d="M262,225.8c0.5-1.4,1.1-2.4,1.8-3.1c0.7-0.7,1.9-1,3.5-1c1.3,0,2.3,0.3,2.9,0.9c0.6,0.6,0.9,1.3,0.9,2.2
                c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.2,0.6-0.3,0.9l-20.3,55.7c-0.5,1.4-1.1,2.4-1.8,3.1c-0.7,0.7-1.9,1-3.5,1c-1.3,0-2.3-0.3-2.8-0.9
                s-0.8-1.3-0.8-2.2c0-0.6,0.1-1.2,0.3-1.7L262,225.8z"/>
            <path class="st4" d="M306.7,255.6l-24.5-8.1c-1.4-0.5-2.4-1.1-3.1-1.7c-0.7-0.7-1-1.8-1-3.3c0-1.3,0.3-2.3,0.9-2.9
                c0.6-0.6,1.4-0.9,2.3-0.9c0.5,0,1,0.1,1.5,0.3l28.8,10c2.5,0.8,3.7,2.5,3.7,5.1v3.2c0,1.3-0.3,2.4-1,3.1c-0.6,0.8-1.6,1.3-2.8,1.8
                l-28.8,10c-0.5,0.2-1.1,0.3-1.5,0.3c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-0.9-1.5-0.9-2.9c0-1.6,0.3-2.7,1-3.3c0.7-0.7,1.7-1.2,3.1-1.7
                L306.7,255.6z"/>
        </g>
    <circle id="circuloOk" class="st5" cx="255.4" cy="256" r="43.2"/>
    <polyline id="tilde" class="st6" points="239.9,255.3 256.3,269.7 298.6,211.9 "/>
    <g id="">
        <g>
            <path class="st7" d="M262.2,257.4c1.4,0.7,2.7,1.5,3.9,2.5c4.2,3.6,6.5,8.2,6.5,13.8c0,2.5,0,5,0,7.4c0,0.5,0.1,0.7,0.6,0.6
                c0.5,0,1,0,1.5,0c0.6,0,1,0.4,1,1c0,1.4,0,2.7,0,4.1c0,0.6-0.4,1-1.1,1c-1.5,0-3,0-4.5,0c-11.1,0-22.3,0-33.4,0
                c-1.2,0-1.5-0.3-1.5-1.5c0-1.1,0-2.2,0-3.4c0-0.8,0.4-1.2,1.2-1.2c0.4,0,0.8,0,1.1,0c0.5,0.1,0.7-0.1,0.7-0.7c0-2.3,0-4.6,0-6.9
                c0-7.6,3.5-13.1,10.1-16.7c0.1,0,0.1-0.1,0.2-0.1c-0.1-0.2-0.3-0.2-0.4-0.3c-6.4-3.6-9.8-8.9-9.9-16.3c0-1.5,0-3,0-4.6
                c0-0.8,0.4-1.2,1-1.2c0.6,0,1,0.5,1,1.2c0.1,2.2-0.1,4.3,0.1,6.5c0.6,5,3,8.8,7.1,11.6c1.2,0.9,2.6,1.5,4,2
                c0.5,0.2,0.9,0.5,0.9,1.1c0,0.6-0.4,0.9-0.9,1c-5.5,2-9.2,5.8-10.8,11.5c-0.3,1.1-0.4,2.3-0.4,3.5c0,2.6,0,5.2,0,7.8
                c0,0.5,0.2,0.6,0.6,0.6c7.4,0,14.8,0,22.2,0c0.2,0,0.4,0,0.6,0c0.6,0.1,0.9,0.5,0.9,1c0,0.5-0.4,1-0.9,1c-0.6,0-1.1,0-1.7,0
                c-8,0-16.1,0-24.1,0c-0.5,0-0.7,0.1-0.7,0.7c0,1.4,0,1.4,1.4,1.4c11.5,0,22.9,0,34.4,0c0.6,0,0.8-0.1,0.8-0.8
                c-0.1-1.3,0-1.3-1.3-1.3c-1.5,0-3,0-4.5,0c-0.9,0-1.3-0.4-1.3-1c0-0.7,0.5-1,1.3-1c0.8,0,1.6,0,2.3,0c0.2,0,0.4,0,0.4-0.3
                c-0.1-3.1,0.2-6.3-0.1-9.4c-0.5-4.9-2.9-8.6-6.9-11.4c-1.2-0.9-2.6-1.6-4-2.1c-0.6-0.2-1.2-0.4-1.2-1.1c0-0.7,0.6-0.9,1.2-1.1
                c4.9-1.9,8.3-5.1,10.1-10.1c0.6-1.5,0.8-3.1,0.8-4.7c0-1.7,0-3.4,0-5.1c0-0.8,0.4-1.3,1-1.2c0.6,0,1,0.5,1,1.3
                c0,2.2,0.2,4.4-0.1,6.5c-0.8,6.4-4,11-9.6,14.2c-0.2,0.1-0.3,0.2-0.5,0.3C262.3,257.3,262.3,257.3,262.2,257.4z"/>
            <path class="st7" d="M255.2,233c-6.3,0-12.5,0-18.8,0c-1.2,0-1.5-0.3-1.5-1.5c0-1.1,0-2.2,0-3.2c0-1.1,0.3-1.4,1.4-1.4
                c10.9,0,21.7,0,32.6,0c1.8,0,3.6,0,5.4,0c0.9,0,1.3,0.4,1.3,1.3c0,1.2,0,2.3,0,3.5c0,1-0.3,1.3-1.3,1.3
                C267.9,233,261.5,233,255.2,233z M255.3,228.9c-5.7,0-11.4,0-17.1,0c-1.2,0-1.2,0-1.2,1.2c0,0.6,0.1,0.8,0.8,0.8
                c11.5,0,22.9,0,34.4,0c1.4,0,1.3,0,1.4-1.4c0-0.6-0.2-0.7-0.7-0.7C267,229,261.1,228.9,255.3,228.9z"/>
            <path  class="st7" d="M255.3,240.1c3.9,0,7.8,0,11.7,0c1.1,0,1.4,0.3,1.4,1.4c-0.1,6.4-5.2,12-11.6,12.7
                c-7.2,0.8-13.3-3.8-14.6-10.9c-0.1-0.6-0.1-1.2-0.2-1.8c0-1,0.3-1.3,1.3-1.3c3.8,0,7.7,0,11.5,0
                C255,240.1,255.2,240.1,255.3,240.1z M255.3,242.1c-3.4,0-6.9,0-10.3,0c-0.8,0-0.8,0-0.7,0.8c0,0,0,0.1,0,0.1
                c1.2,6.1,6.5,9.9,12.7,9.1c4.6-0.6,8.7-4.7,9.3-9.4c0.1-0.5,0-0.7-0.6-0.7C262.2,242.2,258.8,242.1,255.3,242.1z"/>
            <path class="st7" d="M255.2,279.7c-3.9,0-7.7,0-11.6,0c-0.2,0-0.4,0-0.6,0c-0.8-0.1-1.3-1-0.7-1.6c3-3.3,6.2-6.5,10.2-8.7
                c1.9-1.1,3.7-1,5.6,0c2.2,1.2,4.2,2.8,6,4.5c1.3,1.3,2.6,2.6,3.9,3.9c0.4,0.4,0.6,0.8,0.4,1.3c-0.2,0.5-0.6,0.6-1.1,0.6
                C263.2,279.7,259.2,279.7,255.2,279.7z M265,277.6c-1.7-1.8-3.5-3.4-5.4-4.8c-0.9-0.7-1.8-1.3-2.9-1.8c-1.1-0.5-2.1-0.5-3.1,0
                c-1.4,0.7-2.7,1.6-3.9,2.6c-1.5,1.2-2.8,2.6-4.3,4C252.1,277.6,258.5,277.6,265,277.6z"/>
            <path class="st7" d="M256.3,258.4c0,0.4,0,0.7,0,1.1c0,0.6-0.5,1-1,1c-0.5,0-1-0.4-1-1c0-0.7,0-1.4,0-2.1c0-0.6,0.5-1,1-1
                c0.5,0,0.9,0.4,1,1C256.3,257.7,256.3,258,256.3,258.4C256.3,258.4,256.3,258.4,256.3,258.4z"/>
            <path  class="st7" d="M254.2,264.5c0-0.4,0-0.7,0-1.1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1c0,0.7,0,1.4,0,2.1c0,0.6-0.4,1-1,1
                c-0.6,0-1-0.4-1-1C254.2,265.2,254.2,264.8,254.2,264.5z"/>
        </g>
    </g>
    <path id="" class="st8" d="M298.4,256c0,23.8-19.3,43.2-43.2,43.2s-43.2-19.3-43.2-43.2s19.3-43.2,43.2-43.2
        c5.8,0,11.3,1.1,16.4,3.2"/>
    </svg>

<script src="main.js" type="text/javascript"></script>
<body class="landing-page landing-page1 cuadradoVioletaLiso">

    <nav class="navbar navbar-dark navbar-expand-lg navbar-top">
        <div class="container-fluid">
            <img routerLink="/home" class="p-5 img-fluid pr-3 aa-logo-img tamañoLogoIcono" src="../../../../assets/img/logoCompletoAngema.svg" alt="logo">
            <button class=" navbar-toggle" data-target="#menu" data-toggle="collapse" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="menu">
                <ul class="navbar-nav ml-auto mr-auto">

                    <li class="nav-item">
                        <a class="nav-link h2" href="#servicio"> Servicios</a>
                    </li>
                    <li class="nav-item"><a class="nav-link h2" href="#tecnologia">Tecnologías</a></li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/appHotel">Hotel App</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/bi">Business Intelligence</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/quienesSomos">Nosotros</a>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-outline-light botonLogin" href="#contacto"><b>Contacto</b> </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="cuadradoVioletaNav"> </div>

    <div class="">
        <div class="parallax filter-gradient" data-color="blue">
            <div class="parallax-background">
                <div class="container homePadding">
                    <div class="row">
                        <div class="col-md-5 hidden-xs">
                            <div class="parallax-image">
                                <img class="cpu" src="../../../../assets/img/cpu.svg" style="margin-top: 20px" />
                            </div>
                        </div>
                        <div class="col-md-6 col-md-offset-1">
                            <div class="description">
                                <h2><b class="tituloHeader">¡Evolucioná!
                            </b><br>Crecemos junto a vos</h2>
                                <br>
                                <h5>Brindamos <b>soluciones integradas</b> tanto en el desarrollo como en el diseño. Estos dos mundos deben estar en perfecta sincronía.<br><br>
                                    <h3> Y nosotros lo sabemos </h3>

                                </h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tarjeta-desplazadas',
  templateUrl: './tarjeta-desplazadas.component.html',
})
export class TarjetaDesplazadasComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-app',
  templateUrl: './hotel-app.component.html',
})
export class HotelAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

export class ContactoModel {
  id: string;
  name: string;
  company: string;
  phone: string;
  mail: string;
  description: string;

  constructor() {
  }
}


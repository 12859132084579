import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-central',
  templateUrl: './banner-central.component.html',
})
export class BannerCentralComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

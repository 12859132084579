<link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet">

<div class="content">
  <div class="content__container">
    <p class="content__container__text modificadorText">
      CONSTRUIMOS
    </p>

    <ul class="content__container__list">
      <li class="content__container__list__item">Alianzas</li>
      <li class="content__container__list__item">Tus ideas</li>
      <li class="content__container__list__item">Herramientas</li>
      <li class="content__container__list__item">Soluciones</li>
    </ul>
  </div>
</div>

<div class="textoInferiorBi">
  Alianzas<br>
  Ideas<br>
  Herramientas<br>
  Soluciones
</div>

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-bi',
  templateUrl: './bi.component.html',
})
export class BiComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
})
export class PreguntasFrecuentesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

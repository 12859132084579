<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 539.9 487" style="enable-background:new 0 0 539.9 487;" xml:space="preserve">
<style type="text/css">
	
</style>

<path class="st2" d="M437,140.9c0-12.6-9.9-22.4-22.5-22.4c-96.8,0-193.5,0-290.3,0c-10.5,0-19,6.6-21.8,16.7
	c-0.2,0.7,0,1.6-0.7,2.2c0,84.4,0,168.7,0,253.1c0.9,1,0.8,2.4,1.2,3.6c3.4,9.5,11.8,15.1,23,15.1c30,0,60,0,90-0.1
	c2.5,0,3.5,0.5,3.1,3.1c-0.2,1.9-0.2,3.8,0,5.8c0.1,1.8-0.4,2.4-2.3,2.3c-6.3-0.1-12.6,0-18.8,0c-17.1,0-29.2,12-29.2,29.1
	c0,1.5,0,3,0,4.5c67.1,0,134.2,0,201.2,0c0.1-6.1,0.1-12.2-2.9-17.9c-5-9.5-12.8-14.9-23.5-15.5c-7.1-0.4-14.3-0.2-21.5-0.1
	c-1.9,0-2.7-0.4-2.5-2.4c0.3-2.8-1.2-6.6,0.6-8.3c1.6-1.4,5.3-0.5,8.1-0.5c28.1,0,56.2,0,84.4,0c8.2,0,15.1-2.6,20-9.4
	c2.9-3.9,4.3-8.4,4.3-13.2C437,304.7,437.1,222.8,437,140.9z M113.4,137.5c1.4-4.1,4.2-6.8,8.5-7.6c1.4-0.3,2.9-0.3,4.4-0.3
	c95.3,0,190.7,0,286,0c3.3,0,6.5,0.3,9.1,2.5c1.2,1,2.3,2.2,3,3.6c2.3,4.4,1.8,5.3-3,5.3c-50.8,0-101.6,0-152.4,0
	c-51.1,0-102.2,0-153.2,0C112.6,140.9,112.3,140.6,113.4,137.5z M112.5,354.6V149.9h313.3v204.7H112.5z M340.4,431.4
	c6.7,0,12.3,2.3,15.9,8.3c0.5,0.8,1.3,1.7,1,2.6c-0.5,1.1-1.7,0.5-2.6,0.5c-28.5,0-56.9,0-85.4,0s-56.9,0-85.4,0
	c-0.9,0-2.1,0.6-2.6-0.5c-0.4-0.9,0.5-1.8,1-2.6c3.8-6.1,9.5-8.3,16.4-8.3c37.2,0,74.4,0,111.6,0
	C320.4,431.4,330.4,431.4,340.4,431.4z M308.6,417.7c0.2,2-0.5,2.7-2.6,2.6c-12.5-0.1-25-0.1-37.5-0.1c-12,0-23.9-0.1-35.9,0.1
	c-2.1,0-2.8-0.6-2.6-2.6c0.3-2.7-1.2-6.3,0.6-8.1c1.6-1.6,5.2-0.4,7.9-0.4c10.3,0,20.6,0,30.9,0c12.1,0,24.3,0.1,36.4-0.1
	c2.2,0,2.9,0.6,2.7,2.8C308.4,413.9,308.4,415.8,308.6,417.7z M413.3,398c-96-0.1-191.9-0.1-287.9,0c-7.1,0-12.9-4.7-12.7-12.7
	c0.1-6.1,0.1-12.2,0-18.3c0-2,0.5-2.7,2.6-2.7c6.4,0.1,12.7,0.1,19.1,0.1c45,0,89.9,0,134.9,0c51.1,0,102.2,0,153.3,0
	c3.4,0,3.4,0,3.4,3.4c0,5.9-0.1,11.7,0,17.6C426.1,393.6,420.2,398,413.3,398z"/>
</svg>
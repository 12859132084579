import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-texto-movil',
  templateUrl: './texto-movil.component.html',
  styleUrls: ['./texto-movil.scss']
})
export class TextoMovilComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-botones',
  templateUrl: './botones.component.html',
  styles: []
})
export class BotonesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}

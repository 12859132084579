<body class="landing-page">
    <div class="cuadradoVioletaLiso"></div>
    <nav class="navbar navbar-dark navbar-expand-lg navbar-top">
        <div class="container-fluid">
            <img routerLink="/home" class="p-5 img-fluid pr-3 aa-logo-img tamañoLogoIcono" src="../../../../assets/img/logoCompletoAngema.svg" alt="logo">
            <button class=" navbar-toggle" data-target="#menu" data-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>

            <div class="collapse navbar-collapse" id="menu">
                <ul class="navbar-nav ml-auto mr-auto">

                    <li class="nav-item">
                        <a class="nav-link h2" href="#servicio"> Servicios</a>
                    </li>
                    <li class="nav-item"><a class="nav-link h2" href="#tecnologia">Tecnologías</a></li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/appHotel">Hotel App</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/bi">Business Intelligence</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link h2" routerLink="/quienesSomos">Nosotros</a>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-outline-light botonLogin" href="#contacto"><b>Contacto</b> </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
    <div class="cuadradoVioletaQuienes"></div>

    <div class="">
        <div class="parallax filter-gradient" data-color="blue">
            <div class="parallax-background">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 hidden-xs">
                            <div class="parallax-image quienes">
                                <img class="somos" src="../../../../assets/img/quienes somos.svg" style="margin-top: 20px" />
                            </div>
                        </div>
                        <div class="col-md-6 col-md-offset-1">
                            <div class="description">
                                <h2><b class="tituloHeader">¡La unión!
              </b><br>hace la fuerza</h2>
                                <br>
                                <h5><b>¿Qué es mejor que un programador? </b><br> ¡Un programador junto a un diseñador! <br><br> Somos Maximiliano y Gerardo, unos simples emprendedores que aspiraron a tener su propia empresa. Pero ¿cómo lograríamos tal ambición?<br><br>
                                    <h3> Simple, haciendo lo que nos gusta </h3>

                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</body>
<div class="row bloqueInferior">
    <div class="col-md-6">
        <div class="descripcionNosotros paddingInferior">

        </div>

    </div>
    <div class="col-md-4 ">
        <div class="descripcionNosotros paddingInferior ">
            <p><b class="colorVioleta">Gerardo,</b> un apasionado programador que se capacitaba sin parar, día y noche. Se aprendía un curso, luego dos hasta llegar a capacitarse en todo lo que le daba curiosidad. <br><b> ¿Qué lenguaje
          puedo aprender ahora?</b></p><br>
            <p><b class="colorVioleta">Maximiliano,</b> un diseñador meticuloso. No podría estar 5 minutos sin mirar carteles encontrando mejoras y problemáticas a los diseños. <br><b>¿Qué pasaría si lo resolviéramos así?</b></p>
        </div>

    </div>
</div>

<app-footer></app-footer>
<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 539.9 487" style="enable-background:new 0 0 539.9 487;"
     xml:space="preserve">

	<g class="grupoLogo">
		<polyline class="st0" points="116.8,384.2 5.7,330.8 191,9.7 333.1,9.7 76.6,453.7 	"/>
    <polygon class="st0" points="191,9.7 147.6,330.8 333.1,9.7 	"/>

    <path class="st1" d="M427.2,172.8L333.1,9.7H191L332.1,254C367,233.7,399.6,207.2,427.2,172.8z"/>
    <path class="st1" d="M470.7,248c-16.6,39.3-40.9,78.8-76.5,113.4l53.3,92.4l71-122.9L470.7,248z"/>

    <path class="st2" d="M421.9,330.8c-43.4,54.3-109.3,101-208.7,122.9h234.2l71-122.9H421.9z"/>

    <path class="st3"
          d="M76.6,453.7c0,0-0.9-55.1-71-122.9c0,0,421.3,49.5,493.3-303.1C498.9,27.8,545.8,453.7,76.6,453.7z"/>
    <path class="st4" d="M76.6,453.7c0,0,350.3-73.4,422.3-426C498.9,27.8,545.8,453.7,76.6,453.7z"/>
	</g>
  <g>
		<polyline class="st5" points="116.8,383.9 5.7,330.5 191,9.4 333.1,9.4 76.6,453.4 	"/>
    <polygon class="st5" points="191,9.4 147.6,330.5 333.1,9.4 	"/>
    <g>
			<path class="st5" d="M427.2,172.5L333.1,9.4H191l141.1,244.3C367,233.4,399.6,206.9,427.2,172.5z"/>
      <path class="st5" d="M470.7,247.7c-16.6,39.3-40.9,78.8-76.5,113.4l53.3,92.4l71-122.9L470.7,247.7z"/>
		</g>
    <path class="st5" d="M421.9,330.5c-43.4,54.3-109.3,101-208.7,122.9h234.2l71-122.9H421.9z"/>
	</g>
  <path class="st5"
        d="M76.6,453.4c0,0-0.9-55.1-71-122.9c0,0,421.3,49.5,493.3-303.1C498.9,27.5,545.8,453.4,76.6,453.4z"/>
  <path class="st5" d="M76.6,453.4c0,0,350.3-73.4,422.3-426C498.9,27.5,545.8,453.4,76.6,453.4z"/>
</svg>
